<template>
  <div>
    <div class="lis">
    <videos  :videoUrl='videoUrl' :img='img' v-if="videoUrl"/>
    <img :src="img" class="img" v-else  />
    </div>
    <div class="fanh" @click="Videos()">返回</div>
  </div>
</template>

<script>
import videos from '@/components/video';
export default {
    components:{
      videos,
    },
    data() {
        return {
            videoUrl:'',
            img:''
        }
    },
    mounted() {
        this.videoUrl=this.$route.query.viode;
        this.img=this.$route.query.img;
        console.log(this.img)
    },
    methods: {
        Videos(){
             this.$router.push({
                name:'Videos',
            })
        }
    },
}
</script>

<style scoped>
    .fanh{
        width: 80px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background-color: #008cd7;
        color: #fff;
        border-radius: 5px;
        margin: 0 auto;
        margin-top: 5%;
        margin-bottom: 20px;
    }
    .lis{
        overflow: hidden;
    }
    .img{
        width: 100%;
    }
</style>