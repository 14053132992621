import Home from'@/pages/Home';//首页
import ProjectType from'@/pages/ProjectType';//项目类型
import Videos from'@/pages/Videos';//项目类型
import videosPlay from'@/pages/videosPlay';//项目类型
import Qualification from'@/pages/Qualification';//项目类型
import QualificationDetails from'@/pages/Qualification/details'
export default [
    {path:'/Home',name:'Home',component:Home,meta:{isAuth:true}},
    {path:'/ProjectType',name:'ProjectType',component:ProjectType},
    {path:'/Videos',name:'Videos',component:Videos},
    {path:'/Qualification',name:'Qualification',component:Qualification},
    {path:'/QualificationDetails',name:'QualificationDetails',component:QualificationDetails},
    {path:'/videosPlay',name:'videosPlay',component:videosPlay},
]