<template>
    <ul class="vidos-ls">
        <li v-for="item in viode" :key="item.name" @click="Play(item)">
            <img :src="item.src" class="viode" />
            <img src="@/assets/public/pays.png" class="play" v-if="item.viode"/>
        </li>
    </ul>
</template>

<script>
export default {
    data() {
        return {
            viode:[
                // {
                //     name:'密胺餐具去色渍',
                //     src:'/images/public/viode-1.jpg',
                //     viode:'/images/public/viode-1.mp4',
                // },
                // {
                //     name:'勺子清洁复亮',
                //     src:'/images/public/viode-2.jpg',
                //     viode:'/images/public/viode-2.mp4',
                // },
                {
                    name:'JaeSve 清洁消毒液清洁流程',
                    src:'/images/public/viode-3.jpg',
                    viode:'/images/public/viode-3.mp4',
                },
                {
                    name:'展示盘卫生清洁流程',
                    src:'/images/public/zhans-1.jpg',
                }
            ]
        }
    },
    methods: {
        Play(data){
             this.$router.push({
                name:'videosPlay',
                    query:{
                        viode:data.viode,
                        img:data.src
                    }
            })
        }
    },
}
</script>

<style>
    .vidos-ls{
        width: 92%;
        margin: 0 auto;
        margin-top: 3%;
        overflow: hidden;
    }
    .vidos-ls li{
        float: left;
        width: 44%;
        margin-right: 3%;
        padding: 8px 2%;
        background-color: #fff;
        box-shadow: 0px 0px 7px #eee;
        border-radius: 5px;
        position: relative;

    }
    .play{
        color: #fff;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        font-size: 30px;
        width: 40px;
        height: 40px;
    }
    .vidos-ls li .viode{
        width: 100%;
        border-radius: 5px;
        max-height: 280px;
    }
    .vidos-ls li:nth-child(2n){
        margin-right: 0;
    }
</style>