<template>
  <div>
      <ul class="ncols">
        <li v-for="(item,index) in List" :key="index" @click="details(item)">
          <img src="@/assets/img-1.png">
          <p>{{item.name}}</p>
          <i class="el-icon-arrow-right jiss"></i>
        </li>
      </ul>
  </div>
</template>
<script>
export default {
  components: {
  },
  data() {
    return{
      List:[
        {
          name:'JaeSve清洁消毒液(WF20240304142)',
          src:'/images/public/wenz-1.pdf',
        },
        {
          name:'安全评价报告',
          src:'/images/public/wenz-2.pdf',
        }
      ]
    }
  },
  created() {},
  methods: {
     details(data){
          this.$router.push({
            name:'QualificationDetails',
            query:{
              url:data.src
            }
        })
    },
  },
};
</script>
<style scoped>

 .ncols{
    width: 90%;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 9;
  }
  .ncols li{
    background-color: #fff;
    overflow: hidden;
    padding: 6%;
    margin-top: 18px;
    color: #333;
    display: flex;
    justify-content: space-between;
  }
  .ncols li a{
    color: #333;
  }
  .ncols li img{
    float: left;
    width: 25px;
    height: 25px;
  }

  .ncols li p{
    color: #333;
    font-size: 12px;
    line-height: 24px;
    float:left;
  }
  .jiss{
    float: right;
    font-size: 18px;
    color: #999;
    margin-top: 0;
  }
</style>
