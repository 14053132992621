<template>
    <div class="eject-mes">
        <div class="rem">
            <img src="@/assets/rwm.png" class="rwm">
            <p>扫一扫添加企业好友</p>
            <div class="bottom" @click="ESwitch">一键呼叫</div>
        </div>
        <div class="fnas" @click="Home()">返&emsp;回</div>
    </div>
</template>

<script>
import{mapState} from'vuex';
export default {
    props:['Sm'],
    computed:{
    },
    methods: {
        Home(){
            this.$router.push({
                name:'Home'
            })
        },
        ESwitch(){
           window.location.href = "tel:" + 18900999517;
        },
    },
}
</script>

<style scoped>
    .fnas{
        color: #fff;
        padding: 10px 0;
        border: 1px #fff solid;
        width: 66px;
        margin: 0 auto;
        margin-top: 20px;
        border-radius: 10px;
    }
    .rem{
        width: 80%;
        margin: 0 auto;
        overflow: hidden;
        background-color: #fff;
        border-radius: 10px;
        margin-top: 20%;
        padding-bottom: 10%;
    }
    .rem p{
        color: #999;
        text-align:center;
        padding: 12px 0;
    }
    .eject-mes{
        width: 100%;
        max-width: 460px;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 50%;
        overflow: hidden;
        transform: translate(-50%, 0);
        z-index: 99;
        background-color: #0e82d9;
    }
    .eject-text{
        width: 70%;
        padding: 5%;
        background-color: #fff;
        margin: 0 auto;
        margin-top: 40%;
        border-radius: 15px;
        box-shadow: 4px 4px 2px #4a79cd;
    }
    .eject-text p{
        font-size: 14px;
        margin-top: 1rem;
        text-indent: 2em;
        text-align: justify;
    }
    .eject-text  span{
        color: #ff0202;
        font-size: 14px;
        text-indent: 2em;
        text-align: justify;
        display: block;
    }
    .bottom{
        width: 140px;
        height: 3rem;
        line-height: 3rem;
        text-align: center;
        background-color: #168ae0;
        color: #fff;
        border-radius: 10px;
        margin: 0 auto;
        font-size: 18px;
        margin-top: 20px;
        letter-spacing: 2px;
    }
    .las{
        color: #2552eb;
    }
    .rwm{
        width: 80%;
        margin: 0 auto;
        border: 2px #eee solid;
        margin-top: 20%;
    }
</style>